<template>
  <div>
    <div v-if="time > 0" class="ms-3 me-3">
      <vue-count-down :time="time" v-slot="{ days, hours, minutes, seconds }" style="color: white">
        <h1>
          Mutlu Günümüze Kalan：{{ days }} gün, {{ hours }} saat, {{ minutes }} dakika, {{ seconds }} saniye inşaAllah &#128522;
        </h1>
      </vue-count-down>
    </div>
    <div v-if="time < 0" class="ms-3 me-3">
      <h1>
        Rabbim bir ömür boyu saadet nasip etsin inşaAllah &#128522;
      </h1>
    </div>
  </div>
</template>

<script>

import axios from "axios";
import VueCountDown from "@chenfengyuan/vue-countdown"
export default {
  name: 'App',
  components: {
    VueCountDown
  },
  data() {
    return {
      time: 0
    }
  },
  async mounted() {
    const result = await axios.get('https://timezone.abstractapi.com/v1/current_time/?api_key=fc4c52bf7ba24a07a2afa5f48df27261&location=Istanbul')
    const now = new Date(result.data.datetime)
    const futureDay = new Date(2022,9,30,0,0,0,0)
    this.time = futureDay.getTime() - now.getTime();
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
